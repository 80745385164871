module.exports={

                   /* DEV Server URL'S 
   =================================================== */
   //    URL:'https://sdev.noton.dev/noton/graphql',
   //   Auth:'https://sdev-auth.mycw.in/identity/oauth/token',
       // networkStatusUrl:'http://localhost:3000/app/'


                  /* Test Server URL'S 
   =================================================== */
        // URL:'https://s20q1.mycw.in/cw-noton/graphql',
        // Auth:'https://s20q1.mycw.in/cw-identity/oauth/token'

                /* Preprod URL'S 
   =================================================== */
       //   URL:'https://sapp.noton.dev/cw-noton/graphql',
       //  Auth:'https://s360app.mycw.in/cw-identity/oauth/token' 



             /* Base app URL'S 
   =================================================== */
         // URL:'https://base-app-services.noton.dev/cw-noton/graphql',
         // Auth:'https://base-app-auth.noton.dev/cw-identity/oauth/token' 


             /* brandhub app URL'S 
   =================================================== */
        // URL:'https://brand-hub-services.cwcloud.in/cw-noton/graphql',
        // Auth:'https://brand-hub-services.cwcloud.in/cw-identity/oauth/token' 

	  /* Ent Server URL'S
   =================================================== */
      URL:'https://config-services.noton.dev/noton/graphql',
     Auth:'https://config-services.noton.dev/identity/oauth/token',

	                  /* CW_Test Server URL'S
   =================================================== */
       //  URL:'https://stest.noton.dev/cw-noton/graphql',
       //  Auth:'https://stest-auth.mycw.in/cw-identity/oauth/token'

} 
